import React, { Fragment, PureComponent } from "react";
import { NavLink } from "react-router-dom";
import { AJAX_PUBLIC_REQUEST, AJAX_REQUEST, ITEM_COUNT, MEAL_COUNT, GET_STORAGE, BASE_URL, CURRENCY_FORMAT, SET_STORAGE, BUNDLE_OFFER_NEW, BUNDLE_SUB_TOTAL, BUNDLE_ITEM_COUNT } from "../../Constants/AppConstants";
import PropTypes from "prop-types";
import history from '../../history';
import { connect } from 'react-redux';
import $ from "jquery";
import Parser from 'html-react-parser';
import { Row, Col } from "react-bootstrap";
import bannerSlider1 from '../../Assets/images/affiliate_imges/affiliate_slider_1.svg'
import SearchIcon from './../../Assets/images/customer_images/header_search.svg'
import Pagination from '../Common/Pagination';
import ProductsListWithFlavors from "./ProductsListWithFlavors";
import ProductsList from "./ProductsList";
import SingleProductNotAvailableModal from "./SingleProductNotAvailableModal";
import HomeIcon from './../../Assets/images/refer_images/home_icon.svg';
import DeleteIcon from '../../Assets/images/refer_images/bundle-delete.svg';
import UploadIcon from './../../Assets/images/refer_images/upload_product_icon.svg';
import classnames from 'classnames';
import ReactImageFallback from "react-image-fallback";
import CustomProductsList from "./CustomProductsList";
import CustomProductsListWithFlavors from "./CustomProductsListWithFlavors";

class CustomBundlePage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: '',
            products: [],
            products_filtered: [],
            banner_image: [],
            AddedProducts: [],
            url: '',
            searchName: '',
            variationId: '',
            pagenum: 1,
            cart_count: 0,
            // productNotAvailable:["Bulletproof Vitality For Her","Women's Ultimate","Women's Immune Booster"],
            productNotAvailable: [],
            cartLink: '/cart',
            subscription_detail : '',
            NotallowedtoSubscription: false,
            subscription: GET_STORAGE("is_subscription") ? GET_STORAGE("is_subscription"): 'no'
        }
        document.title = "Order -Prestige Labs";
    }

    itemCount = (e) => {
        if(ITEM_COUNT() != 0) {
            const count = Number(ITEM_COUNT()) + Number(MEAL_COUNT());
            this.setState({ cart_count: count });
        } else {
            let bundle = GET_STORAGE("BundleCart") ? JSON.parse(GET_STORAGE("BundleCart")) : [];
            const bundle_count = (BUNDLE_ITEM_COUNT() > 1) ? 1: 0;
            this.setState({ cart_count: bundle_count });
        }
    }

    componentDidMount() {
        if (this.props.user) {
            if (this.props.user.new_agreement_required == "yes") {
                history.push('/agreement');
            }
        }
        if ((this.props.user.roles != undefined) && !Object.values(this.props.user.roles).includes('distributor') && Object.values(this.props.user.roles).includes('master_affiliate')) {
            history.push('/my-account');
        }
        document.querySelector("body").scrollIntoView();
        this.itemCount();
        this.getProducts(this.state.filter_by, this.state.pagenum);
        this.getCart();
    }

    pagenationHandle = (pageNumber) => {
        this.setState({ loading: true });
        this.getProducts(this.state.filter_by, pageNumber);
    }

    filterBy = (e) => {
        let filter_by = e.target.value;
        this.setState({
            loading: true,
            [e.target.name]: filter_by
        })
        this.getProducts(filter_by, 1);
    }

    getProduct_filter = (filterBy, pageNumber, payload) => {
        let data;
        if (Object.keys(this.state.payload).length > 0) {
            if(GET_STORAGE("Bundle")){
                SET_STORAGE("Bundle", []);
                SET_STORAGE("BundleCart", []);
            }
            data = {
                filter_by: filterBy,
                ...payload
            }
        }else{
            data = {
                filter_by: filterBy
            }
        }
        AJAX_PUBLIC_REQUEST("POST", "product/getCustomBundleList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    product_filter: results.response.data,
                    products_filters: results.response.data,
                    loading: false,

                });
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,

                });
            }
        });
    };

    getProducts = (filterBy, pageNumber) => {
        let data = {
                filter_by: filterBy
            }
        AJAX_PUBLIC_REQUEST("POST", "product/getCustomBundleList", data).then(results => {
            if (results.response.code === 1000) {
                this.setState({
                    products: results.response.data,
                    products_filtered: results.response.data,
                    loading: false,
                    // Pagination Config
                    item_count: parseInt(results.response.data.length),
                    total_records: parseInt(results.response.total_records),
                    product_discount: results.response.product_discount,
                    subscription_detail: results.response.subscription_detail,
                    // total_page: parseInt(results.response.total_page),
                    // per_page: parseInt(results.response.per_page),
                    // pagenum: parseInt(results.response.pagenum),
                });
                SET_STORAGE("productDiscount", JSON.stringify(results.response.product_discount));
                SET_STORAGE("CustomSubscriptionDetail", JSON.stringify(results.response.subscription_detail));
            } else {
                this.setState({
                    error: Parser("<p className='text-danger'>" + results.response.message + "</p>"),
                    loading: false,
                    // Pagination Config
                    item_count: 0,
                    total_records: 0,
                    total_page: 0,
                    per_page: 0,
                    pagenum: 1,
                });
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    filterProduct = (e) => {
        this.setState({
            searchName: e.target.value,
            products_filtered: this.state.products.filter(function (product) {
                if (e.target.value === '') {
                    return product;
                } else {
                    let string = product.title.toUpperCase();
                    let substring = e.target.value.toUpperCase();
                    if (string.includes(substring)) {
                        return product;
                    }
                }
            }.bind(this))
        });
    }

    getCart = () => {
        BUNDLE_OFFER_NEW();
        const discountDataFromStorage = GET_STORAGE("Discount_data");
        let discount_data_new = discountDataFromStorage ? JSON.parse(discountDataFromStorage) : '';
        this.setState({ discount_data: discount_data_new });
        const bundleDataFromStorage = GET_STORAGE("Bundle");
        if (!bundleDataFromStorage) {
            this.setState({ AddedProducts: [] });
            SET_STORAGE("Bundle", []);
            SET_STORAGE("BundleCart", []);
            return;
        }
        const products = JSON.parse(bundleDataFromStorage);
        const discountApplied = this.state.discount_data === discount_data_new;
        const updateProductsWithDiscount = (products, discount_data) => {
            return products.map((product, index) => {
                const Inddiscount = discount_data[index];
                const newSalePrice = product.cart_sale_price - Inddiscount;
                return {
                    ...product,
                    custom_bundle_discount_price: newSalePrice.toFixed(2),
                    custom_bundle_discount: Inddiscount.toFixed(2)
                };
            });
        };
        let updatedProducts = products;
        
        if (discount_data_new.length > 0 && !discountApplied) {
            updatedProducts = updateProductsWithDiscount(products, discount_data_new);
            this.setState({
                AddedProducts: updatedProducts,
                discount_data: discount_data_new
            });
            SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
        } else {
            this.setState({ AddedProducts: products, discount_data: discount_data_new });
        }
        const hasSubscriptionStatusZero = products.some(product => product.subscription_status == "0" || product.subscription_status == null);
        this.setState({ NotallowedtoSubscription: hasSubscriptionStatusZero ? true : false });
        if (GET_STORAGE('is_subscription') == "yes") {
            let bundle = JSON.parse(GET_STORAGE("Bundle"));
            let subscription_detail = JSON.parse(GET_STORAGE("CustomSubscriptionDetail"));
            const subscriptionSavePercentage = parseFloat(subscription_detail.subscription_save_percentage_refer);
            const discountFactor = subscriptionSavePercentage / 100;
    
            updatedProducts = bundle.map(product => {
                if (product.custom_bundle_discount_price) {
                    const discountedPrice = product.custom_bundle_discount_price * (1 - discountFactor);
                    return {
                        ...product,
                        custom_bundle_discount_price: discountedPrice.toFixed(2)
                    };
                }
                return product;
            });
    
            this.setState({ AddedProducts: updatedProducts });
            SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
        }
    };
    
    updateAddedProducts = (newProduct) => {
        BUNDLE_OFFER_NEW();
        let discount_data_new = JSON.parse(GET_STORAGE("Discount_data"));
        const products = JSON.parse(newProduct);
        const discountApplied = this.state.discount_data === discount_data_new;
        if (discount_data_new.length > 0 && !discountApplied) {
            const updatedProducts = products.map((product, index) => {
                const Inddiscount = discount_data_new[index];
                const newSalePrice = (product.cart_sale_price - Inddiscount);
                return {
                    ...product,
                    custom_bundle_discount_price: newSalePrice.toFixed(2),
                    custom_bundle_discount: Inddiscount.toFixed(2)
                };
            });
            this.setState({
                AddedProducts: updatedProducts,
                discount_data: discount_data_new
            });
            SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
        } else {
            this.setState({
                AddedProducts: JSON.parse(newProduct),
                discount_data: discount_data_new
            });
        }
        if (GET_STORAGE('is_subscription') == "yes") {
            let bundle = JSON.parse(GET_STORAGE("Bundle"));
            let subscription_detail = JSON.parse(GET_STORAGE("CustomSubscriptionDetail"))
            const subscriptionSavePercentage = parseFloat(subscription_detail.subscription_save_percentage_refer);
            const discountFactor = subscriptionSavePercentage / 100;
            let updatedProducts = bundle.map(product => {
              if (product.custom_bundle_discount_price) {
                const discountedPrice = product.custom_bundle_discount_price * (1 - discountFactor);
                return {
                  ...product,
                  custom_bundle_discount_price: discountedPrice.toFixed(2)
                };
              }
            });
            this.setState({
                AddedProducts: updatedProducts
            });
            SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
        } else{
            if (discount_data_new.length > 0 && !discountApplied) {
                const updatedProducts = products.map((product, index) => {
                    const Inddiscount = discount_data_new[index]; 
                    const newSalePrice = (product.cart_sale_price - Inddiscount);
                    return {
                        ...product,
                        custom_bundle_discount_price: newSalePrice.toFixed(2),
                        custom_bundle_discount: Inddiscount.toFixed(2)
                    };
                });
                this.setState({
                    AddedProducts: updatedProducts,
                    discount_data: discount_data_new
                });
                SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
            } else {
                this.setState({
                    AddedProducts: JSON.parse(newProduct),
                    discount_data: discount_data_new
                });
            }
        }
        const hasSubscriptionStatusZero = products.some(product => (product.subscription_status == "0" || product.subscription_status == null));
        this.setState({ NotallowedtoSubscription: hasSubscriptionStatusZero ? true : false });
    }

    isSubscription = (e) => {
        if(this.state.subscription == "yes"){
            SET_STORAGE("is_subscription", 'no');
        } else if(this.state.subscription == "no"){
            SET_STORAGE("is_subscription", 'yes');
        }
        this.setState ({
            subscription: this.state.subscription == "yes" ? "no" : "yes"
        }, () => {
            if (this.state.subscription == "yes") {
                SET_STORAGE("is_subscription", 'yes');
            }
        });
        this.getCart();
    };


    deleteItem = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete the item?")) {
            let cart = JSON.parse(GET_STORAGE("Bundle"));
            if (cart.splice(row_id, 1)) {
                this.setState({
                    success_alert_wrapper_show: true,
                    server_message: "Item removed from cart successfully"
                });
                SET_STORAGE("Bundle", JSON.stringify(cart));
                let product_discount = JSON.parse(GET_STORAGE("productDiscount"));             
                let totalCountInBundle = cart.length;
                let matchingDiscount = product_discount.find(discountItem => parseInt(discountItem.product_qty) === totalCountInBundle);
                let newDiscountData = 0;
                if (matchingDiscount !== undefined) {
                    newDiscountData = matchingDiscount.discount / matchingDiscount.product_qty;
                }
                SET_STORAGE("Discount_data", JSON.stringify(newDiscountData));
                this.setState({ discount_data: newDiscountData });
        
                this.getCart();
                history.push("/custom-bundle");
               // this.timeOut(5000);
            }
        }
    };

    alertModel = (e) => {
        console.log(ITEM_COUNT(), 'ITEM_COUNT("cart")');
        if(ITEM_COUNT() != 0){
            $(`#${'alertModel'}`).modal({
                backdrop: 'static',
                keyboard: false,
            });
        } else{
            history.push("/cart");
        }
    }

    handleCart = (e) => {
        history.push("/cart");
    }
    
    //Change flavors in side bar
    changeFirstMonthFlovour = (product, productInfo,  activeFlavors, flavorId) => {
        this.removeFlavorFromBundle(product.cart_variation_id);
    
        this.setState({ firstMonthflavorId: flavorId }, () => {
            this.variation(1 + "_" + flavorId, activeFlavors, productInfo);
        });
    };
    
    removeFlavorFromBundle = (variationId) => {
        let bundle = JSON.parse(GET_STORAGE("Bundle")) || [];
        bundle = bundle.filter(item => item.cart_variation_id !== variationId);
    
        SET_STORAGE("Bundle", JSON.stringify(bundle));
    };

    variation = (variationKey, activeFlavors, productInfo) => {
        const product = productInfo;
        const variation = product.variations[variationKey];

        let regular_price = 0;
        let sale_price = 0;
        if (variation) {
            if (parseFloat(variation.sale_price) > 0) {
                regular_price = variation.regular_price;
                sale_price = variation.sale_price;
            } else {
                regular_price = variation.regular_price;
                sale_price = variation.regular_price;
            }
        }
        this.addToBundle({
            cart_product_id: product.product_id,
            cart_product_name: product.title,
            cart_product_sku: variation.sku,
            cart_product_description: product.short_description,
            cart_product_isbundle: product.is_bundle,
            cart_product_weight_lb: 0.0,
            cart_product_slug: product.slug,
            cart_image: product.thumb_image,
            cart_variation_id: variation.variation_id,
            cart_flavor_id: this.state.firstMonthflavorId,
            cart_variation_name: variation.variation_name,
            cart_sale_price: sale_price,
            subscription: null,
            cart_discount_price: sale_price - (sale_price * product.subscription_save_percentage) / 100,
            quantity: 1,
            in_stock: parseInt(variation.quantity, 10),
            regular_price: regular_price,
            subscription_status: variation.subscription_status,
            activeFlavors: activeFlavors,
            productInfo: productInfo
          });
    }

    addToBundle = (event) => {
        let bundle = JSON.parse(GET_STORAGE("Bundle")) || [];
        let discountData = GET_STORAGE("Discount_data") ? JSON.parse(GET_STORAGE("Discount_data")) : null;
    
        let newItem = {
            cart_product_id: event.cart_product_id,
            cart_product_name: event.cart_product_name,
            cart_product_sku: event.cart_product_sku,
            cart_product_description: event.cart_product_description,
            cart_product_isbundle: event.cart_product_isbundle,
            cart_product_weight_lb: event.cart_product_weight_lb,
            cart_product_slug: event.cart_product_slug,
            cart_image: event.cart_image,
            cart_variation_id: event.cart_variation_id,
            cart_flavor_id: event.cart_flavor_id,
            cart_variation_name: event.cart_variation_name,
            cart_sale_price: parseFloat(event.cart_sale_price),
            subscription: event.subscription,
            cart_subscription_msg: event.cart_subscription_msg,
            cart_discount_price: parseFloat(event.cart_discount_price),
            quantity: parseInt(event.quantity, 10),
            in_stock: parseInt(event.in_stock, 10),
            regular_price: parseFloat(event.regular_price),
            discount_data_new: discountData,
            custom_bundle_discount_price: parseFloat(event.cart_sale_price),
            custom_bundle_discount: 0,
            subscription_status: event.subscription_status,
            activeFlavors: event.activeFlavors,
            productInfo: event.productInfo
        };
    
        let data = [];
        let isItemAdded = false;
    
        bundle.forEach((item) => {
            if (item.cart_variation_id === newItem.cart_variation_id) {
                if (item.quantity >= newItem.in_stock) {
                    alert("Out Of Stock");
                } else {
                    item.quantity += newItem.quantity;
                }
                isItemAdded = true;
            }
            data.push(item);
        });
    
        if (!isItemAdded) {
            data.push(newItem);
        }
        SET_STORAGE("Bundle", JSON.stringify(data));
    
        setTimeout(() => {
            this.updateAddedProducts(JSON.stringify(data), '');
        }, 1500);
        this.getCart();
    };

    quantityIncrement = (cart_variation_id, quantity) => {
        const updatedQuantity = quantity + 1;
        this.updateBundleQuantity(cart_variation_id, updatedQuantity);
    };
    
    quantityDecrement = (cart_variation_id, quantity) => {
        if (quantity > 1) {
            const updatedQuantity = quantity - 1;
            this.updateBundleQuantity(cart_variation_id, updatedQuantity);
        } else {
            alert("Quantity cannot be less than 1");
        }
    };

    updateBundleQuantity = (cart_variation_id, updatedQuantity) => {
        let bundle = JSON.parse(GET_STORAGE("Bundle")) || [];
        
        bundle = bundle.map((item) => {
            if (item.cart_variation_id == cart_variation_id) {
                return {
                    ...item,
                    quantity: updatedQuantity,
                };
            }
            return item;
        });
        SET_STORAGE("Bundle", JSON.stringify(bundle));
        this.updateAddedProducts(JSON.stringify(bundle), '');
    };


    render() {
        const product_discount = this.state.product_discount;
        const notAvailablePopup = this.notAvailablePopup;
        // Added ads banner
        let offer_banner_showing_status = false;
        let offer_banner_image = require('../../Assets/images/slideshow_3.jpg');
        let settings = GET_STORAGE('settings');
        if (settings) {
            settings = JSON.parse(settings);
            if (settings) {
                if (settings.offer_banner_showing_status && settings.offer_banner_showing_status == "yes") {
                    offer_banner_showing_status = true;
                    offer_banner_image = settings.offer_banner_image;
                }
            }
        }
        const addedProducts = this.state.AddedProducts;
        let totalProductQtyAdded = 0;
        const filteredAddedProducts = addedProducts.filter(
            (product) => product.cart_product_id != 48
        );
        filteredAddedProducts.forEach((product) => {
            totalProductQtyAdded += parseInt(product.quantity);
        });
        const maxProductQty = (product_discount != undefined) ? Math.max(...product_discount.map(discount => parseInt(discount.product_qty))) : 0;
        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>
                            <div className="suplements_blk custom_bundle_blk p-0">
                                <div className="container-fluid p-0">
                                    <div className="row m-0">
                                        <div className="col-md-8 p-0 aff_cust_bun_left mobilerespons_order_two">
                                            <main className="athletes_list_wrapper custom_bunde_left">
                                                <div className="d-flex align-items-center mb_28 justify-content-between">
                                                    <div className="pro_head_blk">
                                                        <h2 className="font_24 mb_11 text_black text-uppercase fw_el_semi mobilerespons_dnone">BUILD YOUR OWN BUNDLE</h2>
                                                        <nav aria-label="breadcrumb" className="m-0 fw_ar_reg mobilerespons_dnone">
                                                            <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                                                                <li className="breadcrumb-item"><NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                                                                <li className="breadcrumb-item active" aria-current="page">  ~ Supplements ~ Build your Own Bundle </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                    <div className="input_bundle_btn d-flex justify-content-end align-items-center">
                                                        <form className="product-search-form w-90 product_search_input position-relative form_input_blk">
                                                            <div className="search-input-group">
                                                                <input onChange={this.filterProduct} value={this.state.searchName}
                                                                    className="form-control" placeholder="Search" name="searchName" id="filter-by" type="text" />
                                                            </div>
                                                            <div className="search_icon position-absolute d-flex align-items-center justify-content-center">
                                                                <img src={SearchIcon} alt="search icon" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="order_pro_list_blk">
                                                    {
                                                        (this.state.products_filtered.length <= 0) ?
                                                            <tr><td className="text-center" colSpan="3">No Product Matched!</td></tr>
                                                            :
                                                            this.state.products_filtered.map(function (product, key) {
                                                                product.notAvailable = this.state.productNotAvailable.includes(product.title) ? true : false;
                                                                // Added cart item_count
                                                                product.item_count = this.itemCount;

                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            product.hasOwnProperty('flavors') ?
                                                                                <CustomProductsListWithFlavors key={Math.random()} product={product} subscription={this.state.subscription} updateAddedProducts={this.updateAddedProducts} />
                                                                                :
                                                                                <CustomProductsList key={Math.random()} product={product} subscription={this.state.subscription} updateAddedProducts={this.updateAddedProducts} />
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            }.bind(this))
                                                    }
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="shopping-cart-wrapper">
                                                            <NavLink to="/cart" title="Shopping cart">
                                                                <div className="shopping-cart">
                                                                    <span id="expressCartItemCount" className="item-count"> {this.state.cart_count} </span> item(s)
                                                                </div>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </main>
                                        </div>

                                       <div className="col-md-4 p-0 Bundle_Added mobilerespons_order_one">
                                            <div className='custom_bunde_left'>
                                                <h2 className="font_24 mb_11 text_black text-uppercase fw_el_semi mobilerespons_dfelx d-none">BUILD YOUR OWN BUNDLE</h2>
                                                <nav aria-label="breadcrumb" className="mb_20 fw_ar_reg mobilerespons_dfelx d-none">
                                                            <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                                                                <li className="breadcrumb-item"><NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                                                                <li className="breadcrumb-item active" aria-current="page">  ~ Supplements ~ Build your Own Bundle </li>
                                                            </ol>
                                                        </nav>
                                                <h2 className="font_24 mt-0 mb_15 fw-bold">Your Product Bundle</h2>
                                                <div className='bundle_progress_bar w-100 mb_22'>
                                                <ul className='d-flex flex-wrap w-100'>
                                                        {product_discount !== undefined &&
                                                            product_discount
                                                                .sort((a, b) => parseInt(a.product_qty) - parseInt(b.product_qty))
                                                                .map((discount, index) => {
                                                                    const addedProducts = this.state.AddedProducts || [];
                                                                    const filteredAddedProducts = addedProducts.filter(
                                                                        (product) => product.cart_product_id != 48
                                                                    );
                                                                    const productQty = parseInt(discount.product_qty);
                                                                    let totalProductQtyAdded = 0;
                                                                    filteredAddedProducts.forEach((product) => {
                                                                        totalProductQtyAdded += parseInt(product.quantity);
                                                                    });
                                                                    const progressPercentage = totalProductQtyAdded > 0 ? (Math.min(totalProductQtyAdded, productQty) / productQty) * 100 : 0;
                                                                    const isProductAdded = totalProductQtyAdded >= productQty;
                                                                    const productText = discount.product_qty == 1 ? 'Item' : 'Items';
                                                                    const isMaxProductQty = maxProductQty == discount.product_qty;
                                                                    return (
                                                                        <li key={index} className={`${isProductAdded ? 'prod_added' : ''}`}>
                                                                            <div className="discount-progress-bar">
                                                                                <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
                                                                            </div>
                                                                            <span className="round_circle d-flex justify-content-center align-items-center font_12 mb_5 mx-auto">
                                                                                {discount.discount}%
                                                                            </span>
                                                                            <span className="font_10 text_black d-block text-center">
                                                                                {discount.product_qty} {isMaxProductQty && <span className="plus">+</span>} {productText}
                                                                            </span>
                                                                        </li>
                                                                    );
                                                                })}
                                                    </ul>
                                                </div>
                                                <div className='right_bundle_blk'>
                                                    {/* Start added product list*/}
                                                    {
                                                        (this.state.AddedProducts.length <= 0) ? null :
                                                            this.state.AddedProducts?.map(function (product, key) {
                                                                // Added item delete and reApplyCoupon methos
                                                                product.deleteItem = this.deleteItem;
                                                                product.row_id = key;
                                                                console.log(product, 'product');
                                                                SET_STORAGE("qty", JSON.stringify(product.quantity));
                                                                return (
                                                                    // <AddedCustomProductsList key={Math.random() + '-' + product.cart_variation_id} product={product} />
                                                                    <div className={classnames("mb_22 cart_pro_list d-flex align-items-center justify-content-between", { 'pl_error_input': (product.variation_status == "0" || product.product_status == "0" || this.state.product_status == 0) })} key={product.row_id}>
                                                                        <div className="cart_pro_list_left w-100 p-0 d-flex align-items-center">
                                                                            <div className="cart_pro_img">
                                                                                <ReactImageFallback
                                                                                    src={product.cart_image}
                                                                                    fallbackImage={require('../../Assets/images/preloader.gif')}
                                                                                    initialImage={require('../../Assets/images/preloader.gif')}
                                                                                    alt=''
                                                                                    className="cart_product_img" />
                                                                            </div>
                                                                            <div className="cart_pro_det pl_13">
                                                                                <div className='d-flex align-items-start justify-content-between head_del_blk mb_0'>
                                                                                    <h4 className="font_16 text_black let_spa_2 fw_el_bold m-0 line_height_25">{product.cart_product_name}</h4>
                                                                                    <div data-title="Remove" className='d-flex'>
                                                                                        <a onClick={(e) => product.deleteItem(e, product.row_id)} href="#" className="font_12 d-flex red_text fw_ar_reg"><img src={DeleteIcon} /></a>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <span className="d-block items_text font_14 fw_ar_reg mb_9">{product.cart_variation_name}</span> */}
                                                                                {/* {product.cart_variation_name !== '1 month' && (
                                                                                    <span className="d-block items_text font_14 fw_ar_reg mb_9">
                                                                                        {product.cart_variation_name}
                                                                                    </span>
                                                                                )} */}
                                                                                 <div className={`cart_quantity quan_cart_blk w-100 custom_bundle_qty mb_10 ${(product.activeFlavors !== undefined) ? 'quatitywithFla' : ''}`}>
                                                                                    {(product.activeFlavors !== undefined) ? (
                                                                                    <div className="w-100 side-flavors">
                                                                                        <select onChange={(e) => this.changeFirstMonthFlovour(product, product.productInfo, product.activeFlavors, e.target.value)} name="flovour" className="w-100 select_flavour select_flavour_bundle font_16 let_spa_2 text-black fw_ar_reg" value={product.cart_flavor_id}>
                                                                                            {(product.activeFlavors !== undefined) ?
                                                                                                product.activeFlavors.map(function (flavor, key) {
                                                                                                    return (
                                                                                                        <option key={key} value={flavor.hasOwnProperty('id') ? flavor.id : null}>{flavor.hasOwnProperty('value') ? flavor.value : null}</option>
                                                                                                    )
                                                                                                }.bind(this)) : null
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                ) : null}
                                                                                </div>
                                                                                <span className="d-block text_green font_12 fw_ar_reg mb_9">
                                                                                    {product.cart_product_id == '48' ? '0% OFF' : `${BUNDLE_OFFER_NEW()}% OFF`}
                                                                                </span>
                                                                                <div className='d-flex align-items-start justify-content-between cus_pro_quan_blk'>
                                                                                    {/* <div>
                                                                                        <h4 className="font_14 m-0 text_black let_spa_2 text-uppercase fw-reg">{CURRENCY_FORMAT(product.cart_sale_price)}</h4>
                                                                                        <span className="font_14 mb_14 d-block text_opacity  fw_ar_reg line_through">{((product.regular_price != 0 )? product.regular_price : '')}</span>
                                                                                    </div> */}
                                                                                    <div>
                                                                                        <h4 className="font_14 m-0 text_black let_spa_2 text-uppercase fw-reg">{CURRENCY_FORMAT(product.custom_bundle_discount_price)}</h4>
                                                                                        <span className="font_14 mb_14 d-block text_opacity fw_ar_reg line_through">
                                                                                        {((parseFloat(product.custom_bundle_discount_price).toFixed(2) !== parseFloat(product.cart_sale_price).toFixed(2)) 
                                                                                            ? CURRENCY_FORMAT(product.cart_sale_price) 
                                                                                            : '')}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="cart_quantity quan_cart_blk custom_bundle_qty m-0 quatitywithFla qty_cus_bun">
                                                                                    {(product.variation_status !== "0" && product.product_status !== "0" && this.state.product_status != "0") ?
                                                                                        <div className='qunatity_blk w-100'>
                                                                                            <h5 className="text_opacity font_12 m-0">Qty</h5>
                                                                                            <div data-title="Quantity" className="input-group refer_product_input_group">
                                                                                                <div className="input-group-prepend">
                                                                                                    <button type="button"onClick={() => this.quantityDecrement(product.cart_variation_id, product.quantity)} disabled={(product.quantity <= 1) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-minus"></i></button>
                                                                                                </div>
                                                                                                <input onChange={this.changeHandler} value={product.quantity} key={Math.random()} name="quantity[]" type="text" readOnly className="text-center font_13 p-0 form-control form-control-sm product_quantity_content refer_product_quantity_content" step="1" min="0" max="" title="Qty" size="4" pattern="[0-9]*" inputMode="numeric" />
                                                                                                <div className="input-group-prepend">
                                                                                                    <button type="button"  onClick={() => this.quantityIncrement(product.cart_variation_id, product.quantity)}  disabled={(parseInt(product.quantity) >= parseInt(product.in_stock)) ? "disabled" : ""} className="btn btn-sm"><i className="fa fa-plus"></i></button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>:''}
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4 className="font_14 m-0 text_black let_spa_2 text-uppercase fw_ar_bold" data-title="total">
                                                                                           {CURRENCY_FORMAT(product.custom_bundle_discount_price * product.quantity)}
                                                                                        </h4>
                                                                                    </div>
                                                                                </div>
                                                                                {(this.state.subscription_detail.is_subscription == 'yes' && (product.subscription_status == '0' || product.subscription_status == null)) && (
                                                                                <span className="d-block items_text font_14 fw_ar_reg mb_9 text_red">
                                                                                    {product.cart_variation_name != null
                                                                                        ? `${product.cart_product_name} - ${product.cart_variation_name} is not available for subscription. Please remove from your cart to enable subscribe and save.`
                                                                                        : `${product.cart_product_name} is not available for subscription. Please remove from your cart to enable subscribe and save.`}
                                                                                </span>
                                                                                )}
                                                                                {((product.cart_product_id == 48)) && (
                                                                                <span className="d-block items_text font_14 fw_ar_reg mb_9 text_red">
                                                                                    {product.cart_variation_name != null
                                                                                        ? `${product.cart_product_name} - ${product.cart_variation_name} Available for purchase only, product does not qualify for discount.`
                                                                                        : `${product.cart_product_name} Available for purchase only, product does not qualify for discount.`}
                                                                                </span>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            }.bind(this))
                                                    }
                                                    {/* End added product list */}
                                                </div>
                                                <div className="product_upload text-center mb_22">
                                                    <img src={UploadIcon} className="mb_15" />
                                                    <h6 className="font_12 m-0 text_opacity text-center">Add Product for your Bundle</h6>
                                                    {/* <input type="file" /> */}
                                                </div>
                                                {(this.state.subscription_detail.is_subscription == 'no') ? null : (
                                                    <Fragment>
                                                        <label onClick={!this.state.NotallowedtoSubscription ? this.isSubscription : null} className={`w-100 cus_bun_subscri_blk subscri_blk pro_subscri_blk d-flex align-items-center checkbox_label mx-0 mb_22 radio_label ${this.state.NotallowedtoSubscription ? 'disable disabled' : ''}`} htmlFor="purchase_type_subscription" style={this.state.NotallowedtoSubscription ? { opacity: '0.5', cursor: 'not-allowed' } : {}}>
                                                            <div className="sub_lft position-relative">
                                                                <input defaultValue="subscription" name="purchase_type" id="purchase_type_subscription" className="" type="radio" checked={this.state.subscription == "yes"} onChange={this.isSubscription} disabled={this.state.NotallowedtoSubscription}/>
                                                                <span className="checbox"></span>
                                                            </div>
                                                            <div className="sub_rgt pl_16">
                                                                <div className="d-flex align-items-center">
                                                                    <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">
                                                                        Subscribe and Save {this.state.subscription_detail.subscription_save_percentage || 0}% for 1 month
                                                                    </h3>
                                                                </div>
                                                                {this.state.subscription != 'no' && (
                                                                    <div className="d-flex align-items-center mt_5">
                                                                        <span className="d-block font_14 text_black fw_ar_bold let_spa_2">
                                                                            Order will ship every: 1 Month(s)
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </label>
                                                    </Fragment>
                                                )}
                                                <div>
                                                <button 
                                                    className={`shop_more add_cart_btn font_16 banner_text text-center fw_ar_reg add_to_bundle add_bundle_btn w-100 ${totalProductQtyAdded < 2 ? 'disable' : ''}`} style={totalProductQtyAdded < 2 ? { opacity: '0.5', cursor: 'not-allowed' } : {}}
                                                    onClick={totalProductQtyAdded >= 2 ? this.alertModel : undefined} disabled={totalProductQtyAdded < 2}>
                                                    Add Bundle to your Cart { CURRENCY_FORMAT(BUNDLE_SUB_TOTAL() 
                                                    )}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SingleProductNotAvailableModal />
                        </Fragment>
                }

                {/* Custom bundle product modal start  */}
                <div className="modal fade" id={'alertModel'} tabIndex="-1" role="dialog" aria-labelledby="alertModel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header cus-modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body montserrat">
                                <p className="font_16 text_black fw_ar_reg m-0">
                                    You cannot add a custom bundle product to your cart while a regular product is already in it, You can delete regular product to proceed with the custom bundle product.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => this.handleCart()} type="button" className="cus_button" data-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Custom bundle product modal  */}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps)(CustomBundlePage);
